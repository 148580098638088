@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

:root {
  --primary: #3692d9;
  --secondary: #2b3452;
}
body {
  margin: 0;
  /* padding: 20px; */
  font-family: "Poppins";
  background: #282828;
}
* {
  /* color: #fff; */
  margin: 0;
}
p {
  margin: 20px 0;
}
button {
  border: 0;
  padding: 8px;
  border-radius: 4px;
  color: white;
  background: var(--primary);
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.home li:first {
  padding: 0 !important;
  background-color: #282828;
}

.ant-menu li[title="Home"] {
  background-color: #282828 !important;
}

.ant-menu-item-selected {
  background-color: var(--primary) !important;
}

.ant-menu li[title="Search"] {
  margin: 2em 0 1em;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #282828 !important;
}

.searchBarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #282828;
}

.searchBar {
  background-color: #505050;
  border: 1px solid black;
  width: 100%;
  height: auto;
  font-size: 20px;
  color: white;
  text-align: center;
  padding: 4px 0;
}

.searchBar::placeholder {
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}

.searchBar:focus {
  outline: none;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 0px;
}

.main-header {
  background-color: #282828;
  text-align: center;
  padding: 0 0em 0 0em;
  border-bottom: 1px solid #000;
  align-items: center;
}

.main-header button {
  margin: 0 1em;
}

.note-header {
  background-color: #282828;
  align-items: center;
  border-bottom: 1px solid #000;
  display: flex;
  justify-content: center;
  overflow-x: auto;
}

.note-header button {
  margin: 0 1em;
  white-space: nowrap;
  /* width: 9em; */
  /* width: fit-content; */
}

.handleDeleteButton {
  background: red;
}

.ant-layout {
  background: none;
}

.creationButton {
  color: green;
}

.welcomePage {
  height: calc(100svh - 64px);
  text-align: center;
}

#logo {
  margin: 0.75em 0 1em;
  font-size: 30px;
  padding-left: 10;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}

#logo span {
  font-weight: bold;
  border-bottom: 8px solid var(--primary);
  color: lightgray;
}

#logo span span {
  color: var(--primary);
  border-bottom: none;
}

#sideBar {
  background-color: #282828;
  border-right: 1px solid #000;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100vh;
  margin-left: -1px;
}

#sideBarMenu {
  background-color: #282828;
  margin-top: 24px;
  max-height: calc(100% - 294px);
  height: auto;
  overflow: auto;
  border-top: 1px solid #000;
}

.ant-menu-submenu {
  border-radius: 0px !important;
  border-bottom: 1px solid #000;
}

.sidebarUserMenu {
  position: absolute;
  bottom: 0;
  background-color: #282828;
  border-top: 1px solid #000;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
}

.sidebarUserMenuCollapsed {
  display: none;
}

.sidebarUserMenu img {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  margin: 0 1em;
}

.sidebarUserMenu .ant-col:nth-child(3) button {
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.sidebarUserMenu .ant-col:nth-child(3) button:hover {
  border: 1px solid var(--primary);
}

.ant-layout-sider-children .ant-row .ant-col {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.anticon-ellipsis {
  color: grey;
  font-size: 24px;
}

.ant-drawer-body {
  padding: 0 !important;
}

.codeByKenneth {
  color: white;
  position: absolute;
  bottom: 100px;
  align-items: center;
  justify-content: center;
  height: 3em;
  width: 100%;
  background-color: #282828;
  border-top: 1px solid #000;
}

.codeByKenneth a {
  color: white;
}

.codeByKenneth a:hover {
  color: var(--primary);
}

.completedTitle {
  border-top: 1px solid #2b3452a3;
  color: #2b3452a3;
  font-size: 24px;
  margin: 1em 0em 0em 2em;
}

.editor-cluster {
  padding: 1em;
  text-align: center;
  width: 100%;
  justify-content: space-around;
  display: block;
}

@media (max-width: 600px) {
  .ce-popover--opened {
    position: absolute;
    width: 100vw;
    bottom: -100px;
    margin-left: -12px;
  }

  .note-header {
    justify-content: left;
    padding: 0;
  }
}

.completedVis {
  display: none;
}

.completedPlaceholder {
  color: "rgba(100,100,100,0.5)";
}

.codex-editor__redactor {
  position: relative;
}

.ant-card {
  background: hsla(206, 68%, 53%, 1);

  background: radial-gradient(
    circle,
    hsla(206, 68%, 53%, 1) 0%,
    hsla(226, 31%, 25%, 1) 100%
  );

  background: -moz-radial-gradient(
    circle,
    hsla(206, 68%, 53%, 1) 0%,
    hsla(226, 31%, 25%, 1) 100%
  );

  background: -webkit-radial-gradient(
    circle,
    hsla(206, 68%, 53%, 1) 0%,
    hsla(226, 31%, 25%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#3692D9", endColorstr="#2B3452", GradientType=1 );

  border: 1px solid #000;
}

.ant-card-body {
  height: 95px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.ant-card-actions {
  background-color: var(--primary) !important;
  border-top: 1px solid #000 !important;
}

.antCardCoverPhoto {
  aspect-ratio: 15.95 / 9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  text-align: center;
  color: black;
  border-radius: 8px 8px 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.codex-editor__redactor {
  padding-bottom: 300px;
  border-right: 1px solid black;
  border-left: 1px solid black;
  background-color: lightgray;
}

.toggleHomeViewButton {
  cursor: pointer;
  background: #5f5f5f;
  background: hsla(0, 0%, 37%, 1);

  background: radial-gradient(
    circle,
    hsla(0, 0%, 37%, 1) 0%,
    hsla(0, 0%, 24%, 1) 100%
  );

  background: -moz-radial-gradient(
    circle,
    hsla(0, 0%, 37%, 1) 0%,
    hsla(0, 0%, 24%, 1) 100%
  );

  background: -webkit-radial-gradient(
    circle,
    hsla(0, 0%, 37%, 1) 0%,
    hsla(0, 0%, 24%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#5F5F5F", endColorstr="#3E3E3E", GradientType=1 );
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid black;
}

.toggleHomeViewButtonHandle {
  background: hsla(206, 68%, 53%, 1);

  background: radial-gradient(
    circle,
    hsla(206, 68%, 53%, 1) 0%,
    hsla(226, 31%, 25%, 1) 100%
  );

  background: -moz-radial-gradient(
    circle,
    hsla(206, 68%, 53%, 1) 0%,
    hsla(226, 31%, 25%, 1) 100%
  );

  background: -webkit-radial-gradient(
    circle,
    hsla(206, 68%, 53%, 1) 0%,
    hsla(226, 31%, 25%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#3692D9", endColorstr="#2B3452", GradientType=1 );
  border-right: 1px solid #000;
  border-left: 1px solid #000;
}

.cascading-card,
.cascading-card-inner {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
}

.cascading-card::before,
.cascading-card-inner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 16/9;
  background: radial-gradient(
    circle,
    hsla(206, 68%, 53%, 1) 0%,
    hsla(226, 31%, 25%, 1) 100%
  );

  background: -moz-radial-gradient(
    circle,
    hsla(206, 68%, 53%, 1) 0%,
    hsla(226, 31%, 25%, 1) 100%
  );

  background: -webkit-radial-gradient(
    circle,
    hsla(206, 68%, 53%, 1) 0%,
    hsla(226, 31%, 25%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#3692D9", endColorstr="#2B3452", GradientType=1 ); /* adjust this color to match your card's background */
  border: 1px solid #000; /* adjust this color to match your card's border */
  z-index: -1;
}

.cascading-card::before {
  transform: translateX(-15px) translateY(-15px);
  height: 100%;
  border-radius: 8px;
}

.cascading-card-inner::before {
  transform: translateX(-7.5px) translateY(-7.5px);
  height: 100%;
  border-radius: 8px;
}

.ant-picker-dropdown {
  @media (max-width: 768px) {
    inset: 51.9333px auto auto 0px !important;
  }
}
.ant-picker-dropdown .ant-picker-panel-container {
  @media (max-width: 768px) {
    max-width: 100vw !important;
    overflow: auto !important;
  }
}
